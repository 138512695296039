import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const ChatHeader = () => {
  const navigate = useNavigate();
  const handleNewMessage = () => {
    navigate("/ouvidoria");
  };
  const handleToChats = () => {
    navigate("/ouvidoria/chats");
  };
  return (
    <div className="flex items-center justify-center">
      <Grid container spacing={2}>
        <Grid item container justifyContent="space-around">
          <Button onClick={handleNewMessage}>Nova mensagem</Button>
          <Button onClick={handleToChats}>Mensagens</Button>
        </Grid>
      </Grid>
    </div>
  );
};
