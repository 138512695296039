import React from "react";
import { Modal } from "../Modal";
import { BusLineForm } from "../../Forms/BusLineForm";

export const LineModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) {
    return null;
  }
  const handleSuccess = () => {
    onClose();
  };
  let content = <BusLineForm onSuccess={handleSuccess} />;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};
