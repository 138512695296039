import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Box, Typography, Modal, IconButton } from "@mui/material";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { ViewPdfModal } from "../../Modals/ViewPdfModal";
import { ImageUploadModal } from "../../Modals/ImageUploadImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { ImageVisualize } from "../../Visualize/ImageVisualize";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const months = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

export const MensalidadeTable = ({
  fees,
  filters,
  totalPayments = 0,
  onMonthChange = (month) => {},
  onOrderChange = () => {},
}) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(
    months[new Date().getMonth()]
  );
  const [viewPdfModalOpen, setViewPdfModalOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [uploadFormModalOpen, setUploadFormModalOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(0);
  const { showFlashMessage } = useFlashMessage();
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [selectedUpFolder, setSelectedUpFolder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("/admin/institute");
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
    const selectedMonthIndex = months.indexOf(month);
    onMonthChange(selectedMonthIndex);
  };

  const handleOpenUploadForm = (id) => {
    setUploadFormModalOpen(true);
    setSelectedRowId(id);
  };

  const handleVisualize = (url, folder) => {
    setPdfUrl(`${process.env.REACT_APP_API}uploads/pagamento/${url}`);
    setSelectedFileUrl(url);
    setSelectedUpFolder(folder);
    setViewPdfModalOpen(true);
  };

  const handleIsPay = async (id) => {
    try {
      await api.put("/admin/payments", { paymentId: id });
      showFlashMessage("Pagamento aprovado!", "success");
      updateTable();
    } catch (error) {
      showFlashMessage("Falha ao aprovar pagamento!", "error");
      console.error("Erro ao aprovar pagamento", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm("Deseja realmente excluir este curso?");
    if (!confirmation) return;

    try {
      await api.delete(`/admin/payments`, { data: { paymentId: id } });
      window.location.reload();
      showFlashMessage("Exclusão bem-sucedida!", "success");
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.get("/admin/payments");
        setData(result.data.payments);
      } catch (error) {
        console.error("Erro ao obter pagamentos", error);
      }
    };
    fetchData();
  }, []);

  const handleSort = (column, sortDirection) => {
    onOrderChange(column, sortDirection);
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);
    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  const updateTable = async () => {
    try {
      const result = await api.get("/admin/payments");
      setData(result.data.payments);
    } catch (error) {
      console.error("Erro ao atualizar tabela", error);
    }
  };

  const [monthCounts, setMonthCounts] = useState({});

  useEffect(() => {
    const counts = months.reduce((acc, month) => {
      const filteredData = data.filter((fee) => {
        const dueDate = new Date(fee.dueDate);
        return dueDate.getMonth() === months.indexOf(month);
      });
      acc[month] = filteredData.length;
      return acc;
    }, {});

    setMonthCounts(counts);
  }, [data]);

  const ExpandedComponent = ({ data }) => {
    return (
      <Box display="flex" flexDirection="column" p={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenUploadForm(data?.id)}
          sx={{ mb: 2 }}
        >
          Cadastrar comprovante
        </Button>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"center"}
          gap={2}
          flexWrap="wrap"
        >
          {data?.imageUrl && (
            <IconButton
              variant="contained"
              color="secondary"
              onClick={() => handleVisualize(data?.imageUrl, "pagamento")}
              aria-label="visualize"
            >
              <VisibilityIcon sx={{ marginRight: "4px" }} />
              {` ${data?.imageUrl} `}
            </IconButton>
          )}
          {data?.user?.Profile &&
            data?.user?.Profile[0]?.UploadedFiles?.map((file) => (
              <IconButton
                variant="contained"
                color="secondary"
                onClick={() => handleVisualize(file?.filename, "newPayment")}
                sx={{ mb: 2 }}
              >
                <VisibilityIcon sx={{ marginRight: "4px" }} />{" "}
                {` ${file?.filename}`}
              </IconButton>
            ))}{" "}
        </Box>
        {data?.imageUrl && (
          <Button
            variant="contained"
            color={data?.isConfirmed ? "error" : "primary"}
            onClick={() => handleIsPay(data?.id)}
          >
            {!data?.isConfirmed ? "Considerar pago" : "Desaprovar pagamento"}
          </Button>
        )}
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        width: "100px",
      },
      {
        name: "Acadêmico",
        selector: (row) => row.user?.Profile[0]?.name,
        sortable: true,
        width: "200px",
      },
      {
        name: "Instituição",
        selector: (row) =>
          row.user?.Profile[0]?.institute?.name
            ? row.user?.Profile[0]?.institute?.name
            : "Sem instituição cadastrada",
        width: "200px",
      },
      {
        name: "Data de vencimento",
        selector: (row) => {
          const dueDate = new Date(row.dueDate);
          return dueDate.toLocaleDateString("pt-BR");
        },
        width: "150px",
      },
      {
        name: "Valor",
        selector: (row) => {
          const formattedAmount =
            row?.amount?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }) || "R$ 0,00";
          return formattedAmount;
        },
        width: "150px",
      },
      {
        name: "Status",
        selector: (row) =>
          row?.imageUrl
            ? row?.isConfirmed
              ? "Pago"
              : "Pendente" || ""
            : "Comprovante não enviado",
        sortable: true,
        width: "150px",
      },
      {
        name: "Ações",
        cell: (row) => (
          <IconButton
            color="error"
            onClick={() => handleDelete(row.id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        ),
        width: "100px",
      },
    ],
    []
  );

  useEffect(() => {
    const filteredData = fees.filter((fee) => {
      const dueDate = new Date(fee.dueDate);
      const feeMonth = dueDate.getMonth();
      const selectedMonthIndex = months.indexOf(selectedMonth);

      if (filters.name && filters.name.trim() !== "") {
        const normalizedFilter = filters.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const userName = fee.user?.Profile[0]?.name
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return userName?.includes(normalizedFilter);
      }

      return true;
    });

    const counts = months.reduce((acc, month) => {
      const filtered = filteredData.filter((fee) => {
        const dueDate = new Date(fee.dueDate);
        return dueDate.getMonth() === months.indexOf(month);
      });

      acc[month] = filtered.length;
      return acc;
    }, {});
    setMonthCounts(counts);

    setData(filteredData);
  }, [filters, fees, selectedMonth]);

  return (
    <Box sx={{ backgroundColor: "#f0f0f0", width: "100%", borderRadius: 2 }}>
      <Box
        component="nav"
        sx={{ overflowX: "auto", p: 2, backgroundColor: "#f0f0f0" }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          {months.map((month) => (
            <Button
              key={month}
              onClick={() => handleMonthClick(month)}
              variant={selectedMonth === month ? "contained" : "outlined"}
            >
              {month}
            </Button>
          ))}
        </Box>
      </Box>
      <Box p={2}>
        <Typography variant="h6" gutterBottom>
          Total de lançamentos: {totalPayments}
        </Typography>
        <DataTable
          columns={columns}
          data={data}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandableRowExpanded={(row) => expandedRows.includes(row.id)}
          onRowClicked={handleRowClick}
          onSort={handleSort}
          sortServer
        />
      </Box>

      <Modal
        open={viewPdfModalOpen}
        onClose={() => setViewPdfModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxHeight: "80%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
        >
          <Typography id="file-modal-title" variant="h6" component="h2">
            Visualização de Arquivo
          </Typography>
          {selectedFileUrl && (
            <div>
              {selectedFileUrl.endsWith(".pdf") ? (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              ) : (
                <ImageVisualize
                  data={{ newPayment: selectedFileUrl }}
                  folder={selectedUpFolder}
                />
              )}
            </div>
          )}
        </Box>
      </Modal>

      <Modal
        open={uploadFormModalOpen}
        onClose={() => setUploadFormModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box>
          <ImageUploadModal
            id={selectedRowId}
            onClose={() => setUploadFormModalOpen(false)}
          />
        </Box>
      </Modal>
    </Box>
  );
};
