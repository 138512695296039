// TermsContent.js
import React from "react";
import { StyledSection } from "./StyledSection";

export const TermsContent = () => {
  return (
    <div>
      <h1 className="flex items-center justify-center mb-4">
        {" "}
        TERMO DE RESPONSABILIDADE DO ACADÊMICO 2024
      </h1>
      <StyledSection
        title={"DIREITOS"}
        items={[
          "É direito dos acadêmicos e cursos técnicos o transporte realizado pelas empresas contratadas, com porcentagem do subsídio pago pela Prefeitura Municipal de Mallet e o saldo pago pelo usuário do transporte",
          "É direito do acadêmico e cursos técnicos usufruir do serviço de transporte prestado pela referida empresa de forma cordial e em boas condições de uso",
          "É direito do académico e cursos técnicos ser tratado com respeito pelos e demais funcionários da empresa",
          "É direito do acadêmico procurar a diretoria da Associação quando se sentir lesado de algum dos seus direitos",
        ]}
      />
      <StyledSection
        title={"DEVERES"}
        items={[
          "Fica permanentemente proibida a entrada com bebidas alcoólicas dentro dos ônibus que realizam o transporte dos acadêmicos",
          "Não será permitida a entrada de passageiros que apresentem visíveis sinais de uso em excesso de bebidas alcoólicas ou que apresentem visíveis sinais de uso de entorpecentes, bem como será expulso do ônibus o passageiro que for flagrado nessas condições;   ",
          "Fica permanentemente proibido o consumo de bebidas alcoólicas, cigarros, e substâncias entorpecentes dentro do ônibus que realizam o transporte",
          "Fica proibido aos passageiros atirar qualquer tipo de objeto pelas janelas dos ônibus;    ",
          "Fica permanentemente proibido a promoção de festas, confraternizações, jogos como baralho e afins, instrumentos musicais, aparelhos sonoros (somente fone de ouvido) que não sejam próprios do ônibus e também a proibição de excesso de barulho que incomode o restante dos ocupantes",
          "É obrigatória a apresentação da carteirinha acadêmica no embarque do ônibus",
          "Zelando pela segurança dos acadêmicos, fica proibida, sem exceção, a permanência de usuários no interior da cabine de qualquer ônibus;",
          "É proibida qualquer conduta que venha a desrespeitar os demais usuários dos ônibus bem como o motorista",
          "É vedada qualquer ameaça ou agressão física ou moral dirigidas ao motorista e aos demais passageiros",
          "É proibida qualquer perturbação aos passageiros, motoristas, e pessoas que estejam fora do veículo;  ",
          "Ficam proibidas quaisquer exposições em redes sociais no que se refere a Associação ou a empresa prestadora de serviços. ",
          "Qualquer problema, referente aos veículos e/ou motoristas deve ser comunicado, diretamente a um membro Associação ou ao prestador de serviços;",
          "É obrigado o acadêmico estar pontualmente nos lugares de embarque dos ônibus",
          "Apresentar declaração de frequência escolar quando solicitada pela Associação",
          "Fica permanentemente proibida a parada do ônibus em postos de gasolina, bares e afins para comprar bebidas ou qualquer produto desses estabelecimentos",
          "E obrigatória a permanência dos acadêmicos sentados nos bancos durante o período da viagem",
          "É obrigatória a quitação da mensalidade referente ao mês de transporte acadêmico até a data prevista, constando a não efetivação do pagamento (não apresentação de comprovante de depósito), o acadêmico está proibido de utilizar o transporte",

          "Em casos desistência de curso, término de aulas presenciais no primeiro Semestre ou qualquer outra situação que faça o acadêmico deixar de utilizar o transporte, deve-se, obrigatoriamente, comunicar a Associação. Caso não haja a comunicação, as mensalidades serão cobradas normalmente. ",
          "O não pagamento da mensalidade, durante dois meses consecutivos, acarreta a suspensão imediata do transporte, bem como o direito ao subsídio. O acadêmico fica suspenso até que regularize suas pendências. ",
        ]}
      />
      <p>
        §1º Em caso de não cumprimento dos compromissos aqui estabelecidos o
        acadêmico será submetido às seguintes penalidades, a serem mensuradas
        pela Associação de Acadêmicos de Mallet, de acordo com a seguinte
        gradação:
      </p>

      <StyledSection
        items={[
          "Suspensão do uso de transporte acadêmico pelo período de 05 (cinco) dias de aula",
          "Suspensão do uso do transporte acadêmico pelo período de 10 (dez) dias de aula",
          "Perda total do subsídio repassado pela Prefeitura Municipal de Mallet à Associação de Académicos",
          "Na insistência de não cumprimento, expulsão do acadêmico.",
        ]}
        type={"alfa"}
      />
    </div>
  );
};
