import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";

import { StyledButton } from "../Buttons/StyledButton";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { useModal } from "../../hooks/useModal";
import api from "../../utils/api";

export const InstituteTable = ({ institutes }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleVisualize = (data) => {
    openModal("institute-modal", {
      operation: null,
    });
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="flex items-center justify-center">
        <StyledButton onClick={() => handleVisualize(data)}>
          Visualizar Instituto
        </StyledButton>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <button onClick={() => handleDelete(row.id)}>Deletar</button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setData(institutes);
  }, [institutes]);

  const handleDelete = async (id) => {
    console.log(`Excluir instituto com ID: ${id}`);
    try {
      api
        .delete("/admin/institute", { data: { id } })
        .then((response) => {
          console.log("Exclusão bem-sucedida", response);
          window.location.reload();
          showFlashMessage("Exclusão bem-sucedida!", "success");
        })
        .catch((error) => {
          console.error("Erro ao excluir", error);
          showFlashMessage("Falha ao excluir!", "error");
        });
    } catch (error) {
      console.log(`Erro ao excluir instituto com ID: ${id}`);
    }
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        expandableRows
        expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        onRowClicked={handleRowClick}
        expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
};
