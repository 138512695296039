import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import api from "../../utils/api";
import { InstituteSelect } from "./inputs/InstituteSelect";
import { PeriodSelect } from "./inputs/PeriodSelect";
import {
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  InputLabel,
} from "@mui/material";

export const CourseForm = ({ data, onSuccess, onCloseModal }) => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { showFlashMessage } = useFlashMessage();
  const [isEditing, setIsEditing] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [course, setCourse] = useState({
    institute: "",
    period: "",
    name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourse((prevCourse) => ({ ...prevCourse, [name]: value }));
  };

  const fetchData = async () => {
    try {
      const institutionsResponse = await api.get("/admin/institute");
      setInstitutions(institutionsResponse.data.institutes);

      const periodsResponse = await api.get("/admin/period");
      setPeriods(periodsResponse.data.periods);

      if (data) {
        setIsEditing(true);
        Object.entries(data).forEach(([key, value]) => {
          setValue(key, value);
        });
      } else {
        setIsEditing(false);
        reset();
      }
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [data, setValue, reset]);

  const onSubmit = async () => {
    try {
      const endpoint = "/admin/course";
      const response = isEditing
        ? await api.put(endpoint, course)
        : await api.post(endpoint, course);

      showFlashMessage(response.data.message, "success");
      onSuccess();
    } catch (error) {
      console.error("Error:", error);
      showFlashMessage("Erro ao processar o formulário", "error");
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InstituteSelect
                name="institute"
                institutions={institutions}
                selectedInstitution={course.institution}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <PeriodSelect
                periods={periods}
                selectedPeriod={course.period}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="name"
              name="name"
              label="Nome do Curso"
              value={course.name}
              onChange={handleChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: 20, textAlign: "right" }}>
          <Button onClick={onCloseModal} variant="outlined">
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ marginLeft: 10 }}
          >
            {isEditing ? "Editar" : "Cadastrar"}
          </Button>
        </div>
      </form>
    </div>
  );
};
