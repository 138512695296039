import React, { useEffect, useState } from "react";
import { TextInput } from "../../components/Inputs/TextInput";
// import React, { useState } from "react";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { BusLineSelect } from "../../components/Inputs/BusLineSelect";
import { InstituteSelect } from "../../components/Inputs/InstituteSelect";
import { useFlashMessage } from "../../contexts/FlashMessageProvider";
import { ModalidadeSelect } from "../../components/Inputs/ModalidadeSelect";
import { CourseSelect } from "../../components/Forms/inputs/CourseSelect";
import { useNavigate } from "react-router-dom";

export const CadastroForm = () => {
  const [token] = useState(
    localStorage.getItem("academicos-mallet@token") || ""
  );
  const { userId } = useAuth();

  const [profile, setProfile] = useState({});
  const [mode, setMode] = useState("cadastro");
  const { showFlashMessage } = useFlashMessage();
  const [busLines, setBusLines] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  function handleChange(e) {
    // console.log("change", e.target.name, ":", e.target.value);
    setProfile((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!profile.courseId) {
      profile.courseId = profile?.CourseOnInstitute?.course?.id;
    }
    try {
      const response =
        mode === "update"
          ? await api.put("/admin/profile", profile, getHeaders())
          : await api.post("/admin/profile", profile, getHeaders());

      // console.log("response", response);
      showFlashMessage("Cadastrado com sucesso!", "success");
      // navigate(-1);
      // navigate("/", { replace: true });

      navigate("/cadastro", { replace: true });
      // window.location.reload();
    } catch (error) {
      console.error("error", error);
      error?.response?.data?.error?.message
        ? showFlashMessage(error?.response?.data?.error?.message, "error")
        : showFlashMessage("Falha ao cadastrar!", "error");
    }
  };

  const getHeaders = () => ({
    headers: {
      Authorization: `Bearer ${JSON.parse(token)}`,
      "Content-Type": "application/json",
    },
  });

  useEffect(() => {
    if (userId === 0) return;
    async function fetchData() {
      try {
        const url = `/admin/profile/${userId}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${JSON.parse(token)}`,
            "Content-Type": "application/json",
          },
        });
        // console.log("--->", response.data?.profiles[0]);
        setProfile(response.data?.profiles[0]);
        response.data?.profiles[0] && setMode("update");
      } catch (error) {
        console.error("error", error);
      }
    }

    fetchData();
  }, [token, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (profile?.instituteId) {
          const response = await api.get(
            `/admin/course/institute/${profile?.instituteId}`
          );

          const resp = response.data.institutesCourses.map((course) => {
            return course.course;
          });

          setCourses(resp);
        }
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };
    fetchData();
  }, [profile?.instituteId]);

  if (userId === 0) {
    return <div>Carregando...</div>;
  }
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50  px-4 sm:px-6 lg:px-8">
      <div className="max-w-md h-full w-full ">
        <form className="mt-4 space-y-2" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Nome
            </label>
            <TextInput
              placeholder="Nome Completo"
              value={profile?.name || ""}
              name="name"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              E-mail
            </label>

            <TextInput
              name="email"
              placeholder="E-mail"
              value={profile?.email || ""}
              type="email"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              CPF
            </label>
            <TextInput
              name="cpf"
              value={profile?.cpf || ""}
              placeholder="CPF"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              RG
            </label>
            <TextInput
              name="rg"
              value={profile?.rg || ""}
              placeholder="RG"
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Título de Eleitor
            </label>
            <TextInput
              name="tituloEleitor"
              value={profile?.tituloEleitor || ""}
              placeholder="Título de Eleitor"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Telefone
            </label>
            <TextInput
              name="phone"
              value={profile?.phone || ""}
              placeholder="Telefone"
              onChange={handleChange}
              required
            />
          </div>
          <BusLineSelect
            busLines={busLines}
            selectedId={profile?.busLineId || ""}
            name="busLineId"
            onChange={handleChange}
          />
          <InstituteSelect
            name="instituteId"
            selectedId={profile?.instituteId || ""}
            onChange={handleChange}
            institutes={institutes}
          />
          <CourseSelect
            onChange={handleChange}
            courses={courses}
            // selectedCourse={
            //   profile?.modalidade || profile?.CourseOnInstitute?.courseId
            // }
            selectedCourse={
              profile?.courseId || profile?.CourseOnInstitute?.courseId
            }
          />
          <ModalidadeSelect
            name="modalidade"
            onChange={handleChange}
            selectedModalidade={profile?.modalidade || ""}
          />
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {mode === "update" ? "Atualizar" : "Cadastrar"}
          </button>
        </form>
      </div>
    </div>
  );
};
