// PaymentByUserForm.js
import { FaDollarSign } from "react-icons/fa";
import { TextInput } from "../../Inputs/TextInput";
import DatePicker from "react-datepicker";

import { useEffect, useState } from "react";
import api from "../../../utils/api";
import { InstituteSelect } from "../../Inputs/InstituteSelect";
import { UserSelect } from "../../Inputs/UserSelect";

export const PaymentByUserForm = ({ upload = () => {} }) => {
  const [fee, setFee] = useState({
    userId: null,
    amount: null,
  });
  const [busLines, setBusLines] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [institutions, setInstitutions] = useState([]);

  const handleChange = (e) => {
    // console.log([e.target.name], e.target.value);
    setFee({
      ...fee,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleUserSelect = (userId) => {
    setFee({
      ...fee,
      userId: userId,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      typeId: 1,
      userId: fee.userId,
      amount: fee.valor,
      daysAmount: fee?.daysAmount || 0,
      date: new Date().toISOString(),
      dueDate: selectedDate.toISOString(),
    };
    // console.log(payload);
    // console.log("fee ->", fee);

    try {
      console.log(payload);
      const response = await api
        .post("/admin/payments", payload)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error(error);
        });
      upload();
    } catch (error) {
      console.error("Erro ao buscar tipos de pagamentos:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/payments/type");
        setPaymentType(response.data);
      } catch (error) {
        console.error("Erro ao buscar tipos de pagamentos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        console.log("inst", response.data);
        setInstitutions(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <form action="" onSubmit={handleSubmit} className="max-w-md mx-auto">
      <div className="mb-4">
        <TextInput
          name="valor"
          icon={<FaDollarSign />}
          onChange={handleChange}
          placeholder="R$ 0,00"
          type="number"
        />
      </div>
      <div className="mb-4">
        <TextInput
          name="daysAmount"
          onChange={handleChange}
          placeholder="Quantidade de dias"
          type="number"
        />
      </div>
      <div className="mb-4">
        <DatePicker
          name="vencimento"
          selected={selectedDate}
          dateFormat="dd/MM/yyyy"
          onChange={handleDateChange}
          className="p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
        />
      </div>
      <div className="mb-4">
        <UserSelect onSelect={handleUserSelect} />
      </div>
      <div className="mb-4">
        <button
          type="submit"
          className="w-full px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cadastrar
        </button>
      </div>
    </form>
  );
};
