import { FaDollarSign } from "react-icons/fa";
import { TextInput } from "../../Inputs/TextInput";
import DatePicker from "react-datepicker";

import { useEffect, useState } from "react";
import api from "../../../utils/api";
import { InstituteSelect } from "../../Inputs/InstituteSelect";

export const PaymentByInstituteForm = () => {
  const [fee, setFee] = useState();
  const [busLines, setBusLines] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date()); // Adicione um estado para a data
  const [institutions, setInstitutions] = useState([]);
  const handleChange = (e) => {
    console.log([e.target.name], e.target.value);
    setFee({
      ...fee,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      typeId: 2,
      amount: fee?.valor,
      date: new Date().toISOString(), // Use a data selecionada
      dueDate: selectedDate.toISOString(),
      instituteId: fee?.instituteId,
      // userId: 1,
    };
    try {
      console.log("payload", payload);
      const response = await api.post("/admin/payments", payload);
      console.log(response);
      // window.location.reload();
    } catch (error) {
      console.error("Erro ao buscar tipos de pagamentos:", error);
    }
    console.log("->", payload);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/payments/type");
        setPaymentType(response.data);
      } catch (error) {
        console.error("Erro ao buscar tipos de pagamentos:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        console.log("inst", response.data);
        setInstitutions(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <form action="" onSubmit={handleSubmit}>
      <div className="text-white">{JSON.stringify(fee)}</div>
      <TextInput
        name="valor"
        icon={<FaDollarSign />}
        onChange={handleChange}
        placeholder="R$ 0,00"
        type="number"
      />

      <DatePicker
        name="vencimento"
        selected={selectedDate}
        dateFormat="dd/MM/yyyy"
        onChange={handleDateChange}
        className="p-2 w-full border rounded-md focus:outline-none focus:ring focus:border-blue-300"
      />

      <InstituteSelect
        name="instituteId"
        selectedId={fee?.instituteId || ""}
        institutes={institutions}
        onChange={handleChange}
      />

      <button
        type="submit"
        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Cadastrar
      </button>
    </form>
  );
};
