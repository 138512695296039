import React, { useState, useEffect } from "react";
import api from "../../../utils/api";
import { CourseTable } from "../../../components/Tables/CourseTable";
import { useModal } from "../../../hooks/useModal";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";

export const CoursesPage = () => {
  const [courses, setCourses] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleCourseModal = () => {
    openModal("course-modal", {
      operation: null,
      onSuccess: fetchCourses,
    });
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = () => {
    api.get("/admin/course").then((response) => {
      setCourses(response.data.courses);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-8">
        <Typography variant="h4" align="center" gutterBottom>
          Cursos
        </Typography>

        <div className="flex flex-row-reverse ">
          <Button
            variant="contained"
            onClick={handleCourseModal}
            sx={{ height: 48 }}
          >
            Novo Cadastro
          </Button>
        </div>
      </div>
      <CourseTable courses={courses} />
    </div>
  );
};
