import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";

import api from "../../../utils/api";

export const APIRequest = () => {
  const [url, setUrl] = useState("");
  const [method, setMethod] = useState("GET");
  const [body, setBody] = useState("");
  const [response, setResponse] = useState("");

  const handleSendRequest = async () => {
    try {
      const config = {
        method: method,
        url: url,
        data: body !== "" ? JSON.parse(body) : undefined,
      };

      const res = await api(config);
      setResponse(JSON.stringify(res.data, null, 2));
    } catch (error) {
      setResponse(JSON.stringify(error.response.data, null, 2));
    }
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        API Request
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="URL"
            variant="outlined"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="method-label">Method</InputLabel>
            <Select
              labelId="method-label"
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              label="Method"
            >
              <MenuItem value="GET">GET</MenuItem>
              <MenuItem value="PATCH">PATCH</MenuItem>
              <MenuItem value="POST">POST</MenuItem>
              <MenuItem value="PUT">PUT</MenuItem>
              <MenuItem value="DELETE">DELETE</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Body (JSON)"
            multiline
            rows={6}
            variant="outlined"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendRequest}
          >
            Send Request
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Response:</Typography>
          <pre>{response}</pre>
        </Grid>
      </Grid>
    </Container>
  );
};
