import { useEffect, useState } from "react";
import { StyledButton } from "../../../components/Buttons/StyledButton";
import { TextInput } from "../../../components/Inputs/TextInput";
import { BusLineSelect } from "../../../components/Inputs/BusLineSelect";
import { FaDollarSign } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css"; // Importe os estilos do componente de calendário
import api from "../../../utils/api";
import { PaymentTypeSelect } from "../../../components/Inputs/PaymentTypeSelect";
import { InstituteSelect } from "../../../components/Inputs/InstituteSelect";
import { FeesMonth } from "./FeesMonth";
import { PaymentByInstituteForm } from "../../../components/Forms/payments/PaymentByInstitute";
import { PaymentByUserForm } from "../../../components/Forms/payments/PaymentByUserForm";

export const FeesPanel = () => {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>
      <div className="mb-4 border-b border-gray-200 dark:border-gray-700">
        <ul
          className="flex flex-wrap -mb-px text-sm font-medium text-center"
          role="tablist"
        >
          <li role="presentation" className=" me-2 bg-slate-50 rounded">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg ${
                activeTab === "profile" ? "border-blue-500" : ""
              }`}
              onClick={() => handleTabClick("profile")}
              type="button"
              role="tab"
              aria-controls="profile"
              aria-selected={activeTab === "profile"}
            >
              Lançar cobranças por instituição
            </button>
          </li>

          <li role="presentation" className="bg-slate-50 rounded">
            <button
              className={`inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 ${
                activeTab === "contacts" ? "border-blue-500" : ""
              }`}
              onClick={() => handleTabClick("contacts")}
              type="button"
              role="tab"
              aria-controls="contacts"
              aria-selected={activeTab === "contacts"}
            >
              Lançar cobranças individuais
            </button>
          </li>
        </ul>
      </div>
      <div id="default-tab-content">
        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "profile" ? "block" : "hidden"
          }`}
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          {/* <FeesMonth /> */}
          <PaymentByInstituteForm />
        </div>

        <div
          className={`p-4 rounded-lg bg-gray-50 dark:bg-gray-800 ${
            activeTab === "contacts" ? "block" : "hidden"
          }`}
          id="contacts"
          role="tabpanel"
          aria-labelledby="contacts-tab"
        >
          <PaymentByUserForm />
        </div>
      </div>
    </div>
  );
};
