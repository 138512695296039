import React from "react";

export const BusLineSelect = ({ busLines, selectedId, ...rest }) => {
  return (
    <select
      {...rest}
      value={selectedId || ""} // Configuração do valor inicial
      className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
    >
      <option value="" disabled>
        Selecione uma Linha de Ônibus
      </option>
      {busLines?.map((busStop) => (
        <option key={busStop.id} value={busStop.id}>
          {busStop.name}
        </option>
      ))}
    </select>
  );
};
