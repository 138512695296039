import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";
import { StyledButton } from "../../Buttons/StyledButton";

export const LineTable = ({ lines }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleVisualize = (data) => {
    openModal("line-modal", { operation: null, onSuccess: fetchLines });
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="flex items-center justify-center">
        <StyledButton onClick={() => handleVisualize(data)}>
          Visualizar Linha
        </StyledButton>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        sortable: true,
      },
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
      },
      {
        name: "Description",
        selector: (row) => row.description,
        sortable: true,
      },
      {
        name: "Actions",
        cell: (row) => (
          <button onClick={() => handleDelete(row.id)}>Deletar</button>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    setData(lines);
  }, [lines]);

  const fetchLines = async () => {
    try {
      const response = await api.get("/admin/lines");
      setData(response.data.lines);
    } catch (error) {
      console.error("Erro ao buscar as linhas", error);
    }
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm(
      "Deseja realmente excluir este registro?"
    );

    if (!confirmation) {
      return;
    }
    try {
      const response = await api.delete("/admin/bus/line", { data: { id } });

      console.log("Exclusão bem-sucedida", response);
      showFlashMessage("Exclusão bem-sucedida!", "success");

      // Atualize a tabela chamando a função fetchLines
      fetchLines();
      window.location.reload();
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        expandableRows
        expandableRowExpanded={(row) => expandedRows.includes(row.id)}
        onRowClicked={handleRowClick}
        expandableRowsComponent={ExpandedComponent}
      />
    </div>
  );
};
