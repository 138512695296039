import { jwtDecode } from "jwt-decode";
import api from "../utils/api";

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isAble, setIsAble] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [userId, setUserId] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("academicos-mallet@token");
    const data = JSON.parse(localStorage.getItem("academicos-mallet@data"));

    if (token) {
      const decodedToken = jwtDecode(token);

      // Verifica se o token expirou
      if (decodedToken.exp * 1000 < Date.now()) {
        // Token expirado, realiza logout
        logout();
      } else {
        api.defaults.headers.Authorization = `Bearer ${JSON.parse(token)}`;
        setAuthenticated(true);
        if (data) {
          setIsAble(data.isAble);
          setIsAdmin(data.isAdmin);
          setUserId(data.id);
        }
      }
    } else {
      if (location.pathname !== "/login") {
        navigate("/");
      }
    }
  }, []);

  async function login({ access_token }) {
    try {
      console.log(access_token);
      const response = await api
        .post("/login", { access_token: access_token })
        .then((response) => {
          console.log("response ->", response);
          return response.data;
        });
      console.log("Perfil do usuário:", response);
      if (response.token) {
        localStorage.setItem(
          "academicos-mallet@token",
          JSON.stringify(response.token)
        );
        localStorage.setItem(
          "academicos-mallet@data",
          JSON.stringify(response.data)
        );
        setAuthenticated(true);
        setUserId(response.data.id);
      }

      console.log("2-->", response.token, response.data.isAble);
      if (!response.data.isAble) {
        navigate("/cadastro");
      } else {
        navigate("/");
      }
      window.location.reload();
    } catch (error) {
      console.log("error");
    }
  }
  async function loginAlt({ access_token }) {
    try {
      console.log(access_token);
      const response = await api
        .post("/login", {
          "access_token": access_token,
          "change_mode": "true",


        })
        .then((response) => {
          console.log("response ->", response);
          return response.data;
        });
      console.log("Perfil do usuário:", response);
      if (response.token) {
        localStorage.setItem(
          "academicos-mallet@token",
          JSON.stringify(response.token)
        );
        localStorage.setItem(
          "academicos-mallet@data",
          JSON.stringify(response.data)
        );
        setAuthenticated(true);
        setUserId(response.data.id);
      }

      console.log("2-->", response.token, response.data.isAble);
      if (!response.data.isAble) {
        navigate("/cadastro");
      } else {
        navigate("/");
      }
      window.location.reload();
    } catch (error) {
      console.log("error");
    }
  }

  function logout() {
    setAuthenticated(false);
    localStorage.removeItem("academicos-mallet@token");
    api.defaults.headers.Authorization = undefined;
    window.location.reload();
  }

  return { authenticated, logout, loginAlt, userId, isAdmin, isAble, login };
}
