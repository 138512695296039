import React, { useState } from "react";
import { Button, TextField, Box, Autocomplete } from "@mui/material";
import api from "../../utils/api";
import { TextEditor } from "../TextEditor";

const agency = [
  { label: "informações" },
  { label: "presidência" },
  { label: "secretariado" },
  { label: "financeiro" },
  { label: "fiscalização" },
];
export const NewEventForm = ({ update = () => {} }) => {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    date: getCurrentDate(),
    location: "",
    agency: "",
  });
  const [showForm, setShowForm] = useState(true);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeAgency = (event, value) => {
    setFormData({ ...formData, agency: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Formatando a data para o formato ISOString
    const formattedData = {
      ...formData,
      date: new Date(formData.date).toISOString(),
    };
    // Lógica para enviar os dados para o backend ou outras operações
    // console.log("Dados do formulário:", formattedData);
    // Limpar o formulário após o envio
    api
      .post("/admin/event", formattedData)
      .then((response) => {
        // console.log("response", response);
        update();
        setFormData({ title: "", description: "", date: "", location: "" });
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="max-w-md mx-auto">
      <Box sx={{ "& > :not(style)": { mb: 4 } }}>
        <Button
          onClick={toggleForm}
          variant="contained"
          color="primary"
          className="mb-4"
        >
          {showForm ? "Minimizar Formulário" : "Exibir Formulário"}
        </Button>
      </Box>
      {showForm && (
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <Box sx={{ "& > :not(style)": { mb: 4 } }}>
            <TextField
              label="Título do Evento"
              id="title"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />
            <TextEditor
              label="Descrição do Evento"
              id="description"
              name="description"
              value={formData.description}
              onChange={(value) =>
                setFormData({ ...formData, description: value })
              }
            />
            <TextField
              label="Data do Evento"
              id="date"
              name="date"
              type="date"
              value={formData.date}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              label="Local do Evento"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
            />{" "}
            <Autocomplete
              id="orgao"
              name="agency" // Corrigido para 'agency'
              options={agency}
              getOptionLabel={(option) => option?.label}
              onChange={(e, value) =>
                handleInputChangeAgency(e, value?.label || "")
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Órgão"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className="mt-4"
          >
            Cadastrar Evento
          </Button>
        </form>
      )}
    </div>
  );
};
