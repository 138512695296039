import { AppRoutes } from "./public.routes";
// import { AdminRoutes } from "./admin.routes";
// import useAuth from "../hooks/useAuth";

export function Router() {
  // const { authenticated,isAdmin } = useAuth();

  // if (authenticated && isAdmin) {
  //   return <AdminRoutes />;
  // }
  return (
    <>
      <AppRoutes />
    </>
  );
}
