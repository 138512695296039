import React, { useEffect, useState } from "react";
import { TextField, MenuItem, Button, Box } from "@mui/material";
import api from "../../../utils/api";
import { UserSelect } from "../../Inputs/UserSelect";

export const Filters = ({
  filters,
  setFilters,
  filterUserId = false,
  instituteId = false,
  busLineId = false,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [institutes, setInstitutes] = useState([]);
  const [busLines, setBusLines] = useState([]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  const handleUserSelect = (userId) => {
    setFilters({
      ...filters,
      userId: userId,
    });
  };

  const handleChange = (fieldName) => (e) => {
    setFilters((prevState) => ({
      ...prevState,
      [fieldName]: e?.target?.value,
    }));
  };

  const handleClearFilters = () => {
    setFilters({});
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar instituições de ensino:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box p={4}>
      <Box mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleToggleExpand}
          sx={{ mr: 2 }}
        >
          {expanded ? "Ocultar Filtros" : "Exibir filtros"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClearFilters}
        >
          Limpar filtros
        </Button>
      </Box>

      {expanded && (
        <Box mt={2} display="flex" flexDirection="column" gap={2}>
          {filterUserId ? (
            <UserSelect onSelect={handleUserSelect} />
          ) : (
            <TextField
              label="Nome"
              variant="outlined"
              name="name"
              fullWidth
              value={filters?.name || ""}
              onChange={handleChange("name")}
            />
          )}

          {instituteId && (
            <TextField
              select
              name="instituteId"
              label="Selecione uma instituição"
              variant="outlined"
              fullWidth
              value={filters?.instituteId || ""}
              onChange={handleChange("instituteId")}
            >
              {institutes?.length &&
                institutes?.map((institute) => (
                  <MenuItem key={institute.id} value={institute.id}>
                    {institute.name}
                  </MenuItem>
                ))}
            </TextField>
          )}

          {busLineId && (
            <TextField
              select
              name="busLineId"
              label="Selecione uma linha de ônibus"
              variant="outlined"
              fullWidth
              value={filters?.busLineId || ""}
              onChange={handleChange("busLineId")}
            >
              {busLines?.length &&
                busLines?.map((busLine) => (
                  <MenuItem key={busLine.id} value={busLine.id}>
                    {busLine.name}
                  </MenuItem>
                ))}
            </TextField>
          )}
        </Box>
      )}
    </Box>
  );
};
