// StyledSection.js
import React from "react";
import { StyledList } from "./StyledList";

export const StyledSection = ({ title, children, items, type }) => {
  return (
    <section className="mb-8">
      <h2 className="text-xl font-bold mb-2">{title}</h2>

      {children}
      <StyledList items={items} type={type} />
    </section>
  );
};
