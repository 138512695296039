import { Box, Button, Container, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../../utils/api";
import { useModal } from "../../../../hooks/useModal";

export const DeclaracoesErrorsPage = () => {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [expandedRows, setExpandedRows] = useState([]);
  const { openModal } = useModal();
  const fetchErrorsData = async () => {
    try {
      const result = await api.get("/admin/declaracoes/erros");
      setData(result.data.declaracoes);
    } catch (error) {
      console.error("Erro ao obter pagamentos", error);
    }
  };
  useEffect(() => {
    fetchErrorsData();
  }, []);
  const handleValidateList = async () => {
    await api
      .post("/admin/declaracoes/validate")
      .then((res) => {
        fetchErrorsData();
      })
      .catch((error) => {
        console.error();
        fetchErrorsData();
      });
  };
  const handleGenerateList = async () => {
    await api
      .post("/admin/declaracoes/")
      .then((res) => {
        fetchErrorsData();
      })
      .catch((error) => {
        console.error();
        fetchErrorsData();
      });
  };
  const filteredData = data.filter(
    (item) =>
      item.profile?.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.fileState?.toLowerCase().includes(searchText.toLowerCase())
  );

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row.id,
        width: "100px", // Ajusta a largura da coluna
      },
      {
        name: "Acadêmico",
        selector: (row) => row.profile?.name,
        sortable: true,
      },
      {
        name: "Erros",
        selector: (row) => row.fileState,
        sortable: true,
      },
    ],
    []
  );
  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row.id);
      } else {
        return [...prevExpandedRows, row.id];
      }
    });
  };
  const handleSeeDocuments = (data) => {
    openModal("image-modal", "", data);
  };
  const ExpandedComponent = ({ data }) => {
    // return (
    //   <div className="flex items-center justify-center">
    //     <StyledButton onClick={() => handleVisualize(data)}>
    //       Visualizar Cadastro
    //     </StyledButton>
    //     <StyledButton onClick={() => handleSeeDocuments(data)}>
    //       Visualizar documentos
    //     </StyledButton>
    //     {/* {JSON.stringify(data?.user?.Flags[0]?.inativeUser)} */}

    //     {!data?.user?.Flags[0]?.inativeUser ? (
    //       data?.isApproved ? (
    //         <StyledButton onClick={() => handleAproveRegister(data)}>
    //           Desfazer aprovação
    //         </StyledButton>
    //       ) : (
    //         <StyledButton onClick={() => handleAproveRegister(data)}>
    //           Aprovar cadastro
    //         </StyledButton>
    //       )
    //     ) : (
    //       <div></div>
    //     )}
    //     {data?.user?.Flags[0]?.inativeUser ? (
    //       <StyledButton onClick={() => handleDeactiveUser(data?.user)}>
    //         Re Ativar
    //       </StyledButton>
    //     ) : (
    //       <StyledButton onClick={() => handleDeactiveUser(data?.user)}>
    //         Desativar
    //       </StyledButton>
    //     )}
    //   </div>
    // );
    return (
      <div
        className="flex items-center justify-center"
        style={{ margin: "1rem" }}
      >
        <Button variant="contained" onClick={() => handleSeeDocuments(data)}>
          Visualizar documentos
        </Button>
      </div>
    );
  };
  return (
    <Container sx={{ minHeight: "30rem" }}>
      <Box>
        {data?.length ? (
          <Button variant="contained" onClick={handleValidateList}>
            Validar
          </Button>
        ) : (
          <Button variant="contained" onClick={handleGenerateList}>
            Gerar
          </Button>
        )}
      </Box>
      <Box>
        {data?.length ? (
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            subHeader
            subHeaderComponent={
              <TextField
                placeholder="Pesquisar..."
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                variant="outlined"
                size="small"
              />
            }
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            highlightOnHover
            pointerOnHover
            responsive
            expandableRows
            expandableRowExpanded={(row) => expandedRows.includes(row.id)}
            onRowClicked={handleRowClick}
            expandableRowsComponent={ExpandedComponent}
          />
        ) : (
          "Nenhum Erro encontrado"
        )}
      </Box>
    </Container>
  );
};
