// StyledList.js
import React from "react";

export const StyledList = ({ title, items, type }) => {
  switch (type) {
    case "alfa":
      return (
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-2">{title}</h2>
          <ol className="list-none pl-4">
            {items.map((item, index) => (
              <li key={index} className="mb-2">
                <span className="mr-2">
                  {String.fromCharCode(97 + index)}.{" "}
                </span>

                {item}
              </li>
            ))}
          </ol>
        </div>
      );

    default:
      return (
        <div className="mb-8">
          <h2 className="text-xl font-bold mb-2">{title}</h2>
          <ol className="list-none pl-4">
            {items.map((item, index) => (
              <li key={index} className="mb-2">
                <span className="mr-2">
                  {index < 9
                    ? String.fromCharCode(8544 + index) + "."
                    : `${toRoman(index + 1)}.`}
                </span>
                {item}
              </li>
            ))}
          </ol>
        </div>
      );
  }
};

// Função para converter números em algarismos romanos
const toRoman = (num) => {
  const romanNumerals = [
    "I",
    "II",
    "III",
    "IV",
    "V",
    "VI",
    "VII",
    "VIII",
    "IX",
    "X",
    "XI",
    "XII",
    "XIII",
    "XIV",
    "XV",
    "XVI",
    "XVII",
    "XVIII",
    "XIX",
    "XX",
  ];
  return romanNumerals[num - 1];
};
