import React from "react";
import { Modal, Box, Button, Typography, IconButton } from "@mui/material";
import { ImageUploadForm } from "../../../pages/Cadastro/ImageUploadForm";
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%", // 90% da largura em telas pequenas
    sm: 400, // 400px em telas médias e grandes
  },
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: {
    xs: 2, // Padding menor em telas pequenas
    sm: 4, // Padding maior em telas médias e grandes
  },
  zIndex: 1000,
};

export const ImageUploadModal = ({
  isOpen,
  onRequestClose,
  header,
  folder,
  paymentId,
  month,
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: { xs: 1, sm: 2 },
          }}
        >
          <Typography
            variant="h6"
            id="upload-modal-title"
            sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
          >
            {header}
          </Typography>
          <IconButton
            onClick={onRequestClose}
            sx={{ padding: { xs: 0.5, sm: 1 } }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <ImageUploadForm
          folder={folder}
          paymentId={paymentId}
          month={month}
          update={onRequestClose}
        />
      </Box>
    </Modal>
  );
};
