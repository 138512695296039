import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { useModal } from "../../../hooks/useModal";
import { StyledButton } from "../../Buttons/StyledButton";
import { Link } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

export const ReportTable = ({ courses }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();
  const [profiles, setProfiles] = useState([]);
  const [lines, setLines] = useState([]);

  const handleVisualize = (data) => {
    openModal("course-modal", "consult", data);
  };
  const generatePDF = async (url, outputFilePath) => {};
  const handleGeneratePDF = async (data) => {
    // Cria um novo documento PDF
    const doc = new jsPDF({ orientation: "landscape" });

    // Adiciona conteúdo ao documento PDF

    // Define os cabeçalhos da tabela
    const headers = [["ID", "Nome", "Linha", "Curso", "Turno"]];

    // Mapeia os dados para o formato adequado para a tabela
    const rows = data.map((profile) => [
      profile?.profiles[0]?.id || "",
      profile?.profiles[0]?.name || "",
      profile?.profiles[0]?.busLine?.name || "",
      profile?.profiles[0]?.CourseOnInstitute?.course?.name || "",
      profile?.profiles[0]?.CourseOnInstitute?.course?.period?.name || "",
    ]);

    // Adiciona a tabela ao documento PDF
    doc.autoTable({
      head: headers,
      body: rows,
      startY: 30, // Posição Y inicial da tabela
    });

    // Gera o arquivo temporário
    const pdfBlob = doc.output("blob");

    // Cria uma URL temporária para o blob do PDF
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Abre uma nova janela e carrega o PDF
    const newWindow = window.open(pdfUrl, "_blank");
    if (!newWindow) {
      throw new Error("Erro ao abrir nova janela.");
    }
  };

  const handleDelete = async (id) => {
    const confirmation = window.confirm("Deseja realmente excluir este curso?");

    if (!confirmation) {
      return;
    }

    try {
      await api.delete(`/admin/course`, { data: { id } });
      window.location.reload();
      showFlashMessage("Exclusão bem-sucedida!", "success");
    } catch (error) {
      console.error("Erro ao excluir", error);
      showFlashMessage("Falha ao excluir!", "error");
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "ID",
        selector: (row) => row?.id,
        sortable: true,
      },
      {
        name: "Nome",
        selector: (row) => row?.name,
        sortable: true,
      },
      {
        name: "Visualizar",
        selector: (row) => {
          return (
            <div>
              <Link to={row?.link}>Visualizar</Link>
              {/* <button onClick={() => handleGeneratePDF(profiles)}>
                Baixar PDF
              </button> */}
            </div>
          );
        },
        sortable: true,
      },
    ],
    []
  );

  useEffect(() => {
    setData(courses);
  }, [courses]);

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row?.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row?.id);
      } else {
        return [...prevExpandedRows, row?.id];
      }
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar perfis e linhas da API
        const response = await api.get("/admin/reports/coursexline");
        setProfiles(response?.data?.profiles);
        setLines(response?.data?.busLines);
        // console.log("res ->", response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      {/* {JSON.stringify(data)} */}

      <DataTable columns={columns} data={data} onRowClicked={handleRowClick} />
    </div>
  );
};
