import { Button } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useMemo } from "react";

export const ShowFileButton = ({
  children,
  fileType = "",
  styles = "",
  ...props
}) => {
  const fileIcon = useMemo(() => {
    switch (fileType.toLowerCase()) {
      case "pdf":
        return <PictureAsPdfIcon />;
      case "jpg":
      case "jpeg":
      case "png":
        return <ImageIcon />;
      default:
        return <InsertDriveFileIcon />; // ícone genérico para outros tipos de arquivo
    }
  }, [fileType]);

  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      startIcon={fileIcon} // Adiciona o ícone à esquerda do texto
      sx={{ m: 2, p: 2, ...props.sx }} // Estilos com Material UI
      className={styles}
    >
      {children}
    </Button>
  );
};
