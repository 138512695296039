import React from "react";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export const ViewPdfModal = ({ isOpen, onRequestClose, pdfUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Visualizar PDF"
    >
      <div>
        <h2>Visualizar PDF</h2>
        <button onClick={onRequestClose}>Fechar</button>
      </div>
      <iframe
        title="PDF Viewer"
        src={pdfUrl}
        width="100%"
        height="500px"
        frameBorder="0"
      />
    </Modal>
  );
};
