import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { FeesPanel } from "./FeesPanel";
import { FeesMonth } from "./FeesMonth";

export function PaymentPage() {
  const [activeTab, setActiveTab] = useState("profile");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box>
      <Box borderBottom={1} borderColor="divider">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="Payment Tabs"
          variant="scrollable" // Adicione scroll se precisar de mais abas
        >
          <Tab label="Mensalidades" value="profile" />
          <Tab label="Lançar cobranças" value="contacts" />
          {/* Para adicionar outras abas, basta descomentar e usar */}
          {/* <Tab label="Passes" value="dashboard" />
          <Tab label="Settings" value="settings" /> */}
        </Tabs>
      </Box>

      <Box>
        {activeTab === "profile" && (
          <Box p={3}>
            <Typography>
              <FeesMonth />
            </Typography>
          </Box>
        )}
        {activeTab === "contacts" && (
          <Box p={3}>
            <Typography>
              <FeesPanel />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
