import { useEffect, useState } from "react";
import { Filters } from "../../../components/Tables/Filters/Filters";
import api from "../../../utils/api";
import { MensalidadeTable } from "../../../components/Tables/MensalidadeTable";
import { Box, Grid, MenuItem, Pagination, Select } from "@mui/material";

export const FeesMonth = () => {
  const [filters, setFilters] = useState({});
  const [fee, setFee] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [fees, setFees] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalPayments, setTotalPayments] = useState(0);
  const [sortBy, setSortBy] = useState();
  const [sortOrder, setSortOrder] = useState(null);
  const [monthSelected, setMonthSelected] = useState(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
  );

  const handleChange = (e) => {
    setFee({
      ...fee,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const handleMonthChange = (month) => {
    setMonthSelected(
      `${new Date().getFullYear()}-${
        new Date(new Date().getFullYear(), month, 1).getMonth() + 1
      }`
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      typeId: 2,
      amount: fee?.valor,
      date: new Date().toISOString(),
      dueDate: selectedDate.toISOString(),
      busLineId: fee?.busLineId,
    };
    try {
      const response = await api.post("/admin/payments", payload);
    } catch (error) {
      console.error("Erro ao buscar tipos de pagamentos:", error);
    }
  };

  const handleSort = (column, sortDirection) => {
    if (column?.name === "Status") {
      setSortBy("paymentIsConfirmed");
      setSortOrder(sortDirection);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/payments/", {
          params: {
            page,
            pageSize,
            filterMonth: monthSelected,
            sortBy,
            sortOrder,
          },
        });

        setFees(response.data?.payments);
        setTotalPages(response.data?.totalPages || 1);
        setTotalPayments(response.data?.totalPayments || 1);
      } catch (error) {
        console.error("Erro ao buscar tipos de pagamentos:", error);
      }
    };

    fetchData();
  }, [page, pageSize, monthSelected, sortBy, sortOrder]);

  useEffect(() => {
    setPage(1);
  }, [pageSize, monthSelected, sortOrder, sortBy]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={10}>
          <Filters filters={filters} setFilters={setFilters} />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              sx={{ background: "#fff", width: "100%", maxWidth: 120 }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>

      <MensalidadeTable
        fees={fees}
        filters={filters}
        totalPayments={totalPayments}
        onMonthChange={handleMonthChange}
        onOrderChance={handleSort}
      />

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginTop="20px"
        padding="10px"
        bgcolor="#fff"
        borderRadius="1rem"
        sx={{ width: "100%" }}
      >
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
          size="small"
          sx={{
            "& .MuiPaginationItem-root": {
              fontSize: "0.875rem",
            },
          }}
        />
      </Box>
    </Box>
  );
};
