import React, { useState, useEffect } from "react";
import api from "../../../utils/api";
import { InstituteTable } from "../../../components/Tables/InstituteTable";
import { useModal } from "../../../hooks/useModal";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { StyledButton } from "../../../components/Buttons/StyledButton";

export const InstitutesPage = () => {
  const [institutes, setInstitutes] = useState([]);
  const { showFlashMessage } = useFlashMessage();
  const { openModal } = useModal();

  const handleInstituteModal = () => {
    openModal("institute-modal", {
      operation: null,
      onSuccess: fetchInstitutes,
    });
  };

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const fetchInstitutes = () => {
    api.get("/admin/institute").then((response) => {
      setInstitutes(response.data.institutes);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-8">
        <h1 className="flex justify-center text-2xl font-semibold text-gray-800 text-center">
          Instituições
        </h1>
        <div className="flex flex-row-reverse">
          <StyledButton onClick={handleInstituteModal}>
            Novo Cadastro
          </StyledButton>
        </div>
      </div>
      <InstituteTable institutes={institutes} />
    </div>
  );
};
