import { useEffect, useState } from "react";
import api from "../../utils/api";

import { EventsTable } from "../../components/Tables/EventsTable";
// import { GoogleLogin } from "@react-oauth/google";

export function Home() {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    api.get("/event").then((response) => {
      setEvents(response.data.events);
    });
  }, []);

  return (
    <div className="container mx-auto p-4 h-screen">
      <div className="text-center">
        <h1 className="text-3xl font-bold text-white mb-4">
          Bem-vindo à Plataforma Acadêmica do Município de Mallet
        </h1>
        <p className="text-gray-100" style={{ marginBottom: "1rem" }}>
          Explore eventos acadêmicos e mantenha-se atualizado.
        </p>
      </div>
      {/* {JSON.stringify(events)} */}
      {events?.length > 0 && <EventsTable events={events} />}

      {/* <EventsBoard events={events} /> */}
    </div>
  );
}
