import { ReportTable } from "../../../components/Tables/ReportTable";

import { useEffect, useState } from "react";
import { getBusLines } from "../../../Actions/getBusLines";

export const ReportsCentralPage = () => {
  const [busLines, setBusLines] = useState([]);
  const [linesReports, setLinesReports] = useState([]);
  const [coursesxLines, setCoursesxLines] = useState([]);
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getBusLines();
        setBusLines(data);
        let auxLinesReports = [];
        let auxCoursesxLines = [];

        data?.map((li, index) => {
          console.log(li);
          auxLinesReports.push({
            id: index + 2, // Índice iniciando em 1
            name: `Relatório de linhas por instituição  ${li?.name}`,
            link: `/manager/reports/institutebyline?line=${li?.id}`,
          });

          auxCoursesxLines.push({
            id: index + 2 + data.length, // Índice iniciando após os relatórios de linesReports
            name: `Relatório de alunos por linha ${li?.name} e Cursos`,
            link: `/manager/reports/userbyline?line=${li?.id}`,
          });
        });

        setLinesReports(auxLinesReports);
        setCoursesxLines(auxCoursesxLines);
      } catch (error) {
        console.error("Erro ao buscar as linhas de ônibus:", error);
        setBusLines([]);
        setLinesReports([]);
        setCoursesxLines([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const updatedReports = [
        {
          id: 0,
          name: "Relatório de alunos por linha X Cursos",
          link: "/manager/reports/coursexline",
        },
        {
          id: 1,
          name: "Relatório de passageiros",
          link: "/manager/reports/userbyline",
        },
        ...linesReports,
        ...coursesxLines,
      ];
      setReports(updatedReports);
    };
    fetchData();
  }, [linesReports, coursesxLines]);

  return (
    <div>
      <h1>Relatórios</h1>
      <ReportTable courses={reports} />
      <hr />
    </div>
  );
};
