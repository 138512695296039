import React from "react";

import { LoginButton } from "../../components/LoginButton";

export const Login = () => {
  return (
    <div>
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div className="flex flex-col mt-6 text-center text-base font-bold text-gray-900">
            Se cadastre no botão abaixo para ter acesso ao sistema
            <LoginButton label="Cadastrar" />
          </div>
        </div>
      </div>
    </div>
  );
};
