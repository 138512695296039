import React from "react";

export const Modal = ({ title = "", isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  return (
    // <div className="fixed inset-0 flex items-center justify-center z-10 overflow-y-auto">
    <div className="fixed inset-0  flex items-center justify-center z-10 ">
      <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
      <div className="relative z-20 w-3/4 h-4/4">
        <div className="modal-content h-3/4 bg-white rounded-md">
          <div className="flex justify-between m-4">
            {" "}
            <h2>{title}</h2>
            <button
              className="flex justify-center items-center bg-red-600 h-1 w-1 text-white rounded p-4 m-4 focus:bg-red-400"
              onClick={onClose}
            >
              X
            </button>
          </div>
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};
