import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App } from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter } from "react-router-dom";
import { ModalProvider } from "./contexts/ModalsProvider";
import { FlashMessageProvider } from "./contexts/FlashMessageProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
const googleClientId =
  "978607282822-sep1vc0fq51bpc5mohpq54nummagincp.apps.googleusercontent.com";
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={googleClientId}
      onScriptLoadError={(error) => console.log("google", error)}
    >
      <BrowserRouter>
        <AuthProvider>
          <FlashMessageProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </FlashMessageProvider>
        </AuthProvider>
      </BrowserRouter>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
