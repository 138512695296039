import React from "react";

export const ModalidadeSelect = ({ selectedModalidade, onChange, ...rest }) => {
  return (
    <select
      {...rest}
      value={selectedModalidade || ""} // Configuração do valor inicial
      onChange={onChange}
      className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
      required
    >
      <option value="" disabled>
        Selecione uma modalidade
      </option>
      {/* Adicionando as opções fixas */}
      <option value="presencial">Presencial</option>
      <option value="semi-presencial">Semi Presencial</option>
    </select>
  );
};
