import api from "../utils/api";

export const getBusLines = async () => {
  try {
    const response = await api.get("/admin/bus/line");

    if (response.status === 200) {
      return response.data?.busLines;
    } else {
      throw new Error("Falha ao obter as linhas de ônibus");
    }
  } catch (error) {
    console.error("Erro ao buscar as linhas de ônibus:", error.message);
    return { error: error.message };
  }
};
