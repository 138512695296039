// ModalsProvider.js
import React, { createContext, useState } from "react";
import { ProfileModal } from "../components/Modals/ProfileModal";
import { DocumentsModal } from "../components/Modals/DocumentsModal";
import { LineModal } from "../components/Modals/LineModal";
import { InstituteModal } from "../components/Modals/InstituteModal";
import { CourseModal } from "../components/Modals/CourseModal";

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({});
  const [operation, setOperation] = useState(null);
  const [data, setData] = useState(null);

  const openModal = (type, operation = null, data = null) => {
    operation && setOperation(operation);
    data && setData(data);
    console.log("open", operation, type, data);
    setModals((prevModals) => ({
      ...prevModals,
      [type]: true,
      operation,
      data,
    }));
  };

  const closeModal = (type) => {
    setModals((prevModals) => ({ ...prevModals, [type]: false }));
  };

  const getModal = (type) => {
    // const operation = modals[type]?.operation || null;

    console.log("getModal", operation, modals[type], data);
    switch (type) {
      case "profile-modal":
        return operation !== null ? (
          <ProfileModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
            operation={operation}
            data={data}
          />
        ) : (
          <ProfileModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
          />
        );
      case "image-modal":
        return (
          <DocumentsModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
            data={data}
          />
        );
      case "line-modal":
        return (
          <LineModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
          />
        );
      case "institute-modal":
        return (
          <InstituteModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
          />
        );
      case "course-modal":
        return (
          <CourseModal
            isOpen={modals[type] || false}
            onClose={() => closeModal(type)}
          />
        );

      // Adicione outros casos conforme necessário

      default:
        return null;
    }
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {/* Renderiza o modal correspondente ao tipo selecionado */}
      {Object.keys(modals).map((type) => getModal(type))}
    </ModalContext.Provider>
  );
};
