import React, { useEffect, useState } from "react";
import api from "../../../utils/api";
import { EventsViewTable } from "../../../components/EventsViewTable";
import { NewEventForm } from "../../../components/NewEventForm";
import { EventsTable } from "../../../components/Tables/EventsTable";

export const Events = () => {
  const [events, setEvents] = useState([]);
  const update = async () => {
    api
      .get("/admin/event")
      .then((response) => {
        setEvents(response.data.events);
      })
      .catch((error) => console.error("error", error));
  };
  useEffect(() => {
    api
      .get("/admin/event")
      .then((response) => {
        setEvents(response.data.events);
      })
      .catch((error) => console.error("error", error));
  }, []);
  return (
    <div className="min-h-screen flex items-center justify-center w-full">
      <div className="w-full space-y-8">
        <h2 className="mt-6 text-center text-3xl font-bold text-gray-900">
          Eventos Admin
        </h2>
        <NewEventForm update={update} />
        {/* <EventsViewTable data={events} /> */}
        <EventsTable events={events} update={update} isEdit />
      </div>
    </div>
  );
};
