import React, { useState, useEffect } from "react";
import api from "../../../utils/api";

import { useModal } from "../../../hooks/useModal";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { LineTable } from "../../../components/Tables/LineTable";

export const LineManagementPage = () => {
  const [lines, setLines] = useState([]);

  const { openModal } = useModal();

  const handleLineModal = () => {
    openModal("line-modal", { operation: null });
  };

  useEffect(() => {
    // Aqui você deve ajustar a rota de acordo com a sua API
    api.get("/admin/bus/line").then((response) => {
      setLines(response.data.busLines);
    });
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-8">
        <h1 className="text-2xl font-semibold text-gray-800 text-center">
          Gerenciamento de Linhas
        </h1>

        <button onClick={handleLineModal}>Nova Linha</button>
      </div>
      <LineTable lines={lines} />
    </div>
  );
};
