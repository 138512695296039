import React from "react";
import { Modal } from "../Modal";

import { InstituteForm } from "../../Forms/InstituteForm";

export const InstituteModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) {
    return null;
  }

  let content = <InstituteForm data={data} onSuccess={onClose} />;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};
