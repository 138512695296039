import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";
import DataTable from "react-data-table-component";
import api from "../../../utils/api";
import { useFlashMessage } from "../../../contexts/FlashMessageProvider";
import { ImageUploadModal } from "../../Modals/ImageUploadImage";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

export const EventsTable = ({ events, update = () => {}, isEdit = false }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [visibleItems, setVisibleItems] = useState(3);
  const [data, setData] = useState([]);
  const { showFlashMessage } = useFlashMessage();

  const tableContainerRef = useRef(null); // Referência ao contêiner da tabela

  const [selectedRowId, setSelectedRowId] = useState(0);
  const [uploadFormModalOpen, setUploadFormModalOpen] = useState(false);

  const handleDelete = useCallback(
    async (id) => {
      // console.log("del", id);
      const confirmation = window.confirm(
        "Deseja realmente excluir este curso?"
      );

      if (!confirmation) {
        return;
      }
      const payload = { id: id };

      try {
        await api
          .delete(`/admin/event/${id}`, { data: { id } })
          .then((response) => {
            // console.log("res", response);
            showFlashMessage("Exclusão bem-sucedida!", "success");
            update();
          })
          .catch((error) => {
            console.error("error", error);
            console.error("Erro ao excluir", error);
            showFlashMessage("Falha ao excluir!", "error");
          });
      } catch (error) {}
    },
    [showFlashMessage]
  );
  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 3);
    scrollToTop(); // Rolagem para o topo ao carregar mais itens
  };

  const scrollToTop = () => {
    if (tableContainerRef.current) {
      tableContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  const downloadFile = async (fileUrl) => {
    try {
      // Faz uma solicitação HEAD para obter o tipo de conteúdo do arquivo
      const headResponse = await fetch(
        `${process.env.REACT_APP_API}uploads/evento/${fileUrl}`,
        {
          method: "HEAD",
        }
      );

      // Obtém o tipo de conteúdo do cabeçalho da resposta
      const contentType = headResponse.headers.get("content-type");

      // Faz uma solicitação GET para obter o arquivo
      const fileResponse = await fetch(
        `${process.env.REACT_APP_API}uploads/evento/${fileUrl}`
      );
      const blob = await fileResponse.blob();

      // Cria um objeto de blob com o tipo de conteúdo do arquivo
      const blobWithContentType = new Blob([blob], { type: contentType });

      // Cria um link temporário
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blobWithContentType);

      // Define o atributo 'download' para forçar o download do arquivo com o nome original
      link.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);

      // Adiciona o link ao documento
      document.body.appendChild(link);

      // Simula o clique no link para iniciar o download
      link.click();

      // Remove o link do documento após o download
      document.body.removeChild(link);
    } catch (error) {
      console.error("Erro ao fazer o download do arquivo:", error);
    }
  };
  const ExpandedComponent = ({ data }) => {
    const handleDeleteFile = async (data) => {
      // console.log(data);
      await api
        .delete(`/admin/event/file/${data?.event?.id}`)
        .then((response) => {
          // console.log("res", response);
          showFlashMessage("Exclusão bem-sucedida!", "success");
          update();
        })
        .catch((error) => {
          console.error("error", error);
          console.error("Erro ao excluir", error);
          showFlashMessage("Falha ao excluir!", "error");
        });
    };
    return (
      <div
        className="flex flex-col items-center justify-center"
        style={{ marginTop: "2rem", marginLeft: "2rem" }}
      >
        <div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: data?.event?.description }}
            />
          </div>
          {data?.event?.EventsFiles?.length > 0 && (
            <TableContainer component={Paper} sx={{ margin: "0.5rem" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Documento</TableCell>
                    <TableCell>Download</TableCell>
                    {isEdit && <TableCell>Ações</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.event?.EventsFiles?.map((item, index) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell>{`${data?.event?.title}(${
                          index + 1
                        })`}</TableCell>
                        <TableCell>
                          <IconButton
                            color="primary"
                            aria-label="Baixar"
                            onClick={() => downloadFile(item?.file)}
                          >
                            <CloudDownloadIcon />
                          </IconButton>
                        </TableCell>
                        {isEdit && (
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              sx={{ margin: "0.2rem" }}
                            >
                              Editar
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              sx={{ margin: "0.2rem" }}
                              onClick={() => handleDeleteFile(data)}
                            >
                              {" "}
                              Excluir
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        {isEdit && (
          <Button
            onClick={() => handleOpenUploadForm(data?.event?.id)}
            variant="contained"
            color="primary"
            sx={{ margin: "1rem" }}
          >
            Cadastrar documento
          </Button>
        )}
      </div>
    );
  };
  const columns = useMemo(
    () => [
      {
        name: "Título",
        selector: (row) => row?.event?.title,
        sortable: true,
      },
      {
        name: "Orgão",
        selector: (row) => row?.event?.agency,
        sortable: true,
      },
      {
        name: "Descrição",
        // selector: (row) => row?.event?.description,
        selector: (row) => (
          <div
            dangerouslySetInnerHTML={{
              __html: row?.event?.description.slice(0, 200),
            }}
          />
        ),
        sortable: true,
      },
      {
        name: "Data",
        selector: (row) =>
          new Date(row?.event?.date)
            .toLocaleDateString("pt-BR")
            .split("/")
            // .reverse()
            .join("/"),
        sortable: true,
      },
      // {
      //   name: "Local",
      //   selector: (row) => row?.event?.location,
      //   sortable: true,
      // },
      isEdit && {
        name: "Ações",
        cell: (row) => (
          <Button
            onClick={() => handleDelete(row?.event?.id)}
            variant="contained"
            color="error"
          >
            Deletar
          </Button>
        ),
      },
    ],
    [handleDelete, isEdit]
  );

  useEffect(() => {
    setData(events);
  }, [events]);

  const handleOpenUploadForm = (id) => {
    setUploadFormModalOpen(true);
    setSelectedRowId(id);
  };

  const handleRowClick = (row) => {
    const isRowExpanded = expandedRows.includes(row?.id);

    setExpandedRows((prevExpandedRows) => {
      if (isRowExpanded) {
        return prevExpandedRows.filter((id) => id !== row?.id);
      } else {
        return [...prevExpandedRows, row?.id];
      }
    });
  };

  return (
    <div>
      <Paper elevation={3} sx={{ borderRadius: "20px", paddingBottom: "5rem" }}>
        <DataTable
          columns={columns}
          data={events.slice(0, visibleItems)}
          expandableRows
          expandableRowExpanded={(row) => expandedRows.includes(row?.event?.id)}
          onRowClicked={handleRowClick}
          expandableRowsComponent={ExpandedComponent}
        />
      </Paper>

      {events?.length > visibleItems && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            onClick={handleLoadMore}
            sx={{ margin: "0.5rem" }}
          >
            Carregar mais
          </Button>
        </Box>
      )}
      {isEdit && (
        <ImageUploadModal
          isOpen={uploadFormModalOpen}
          onRequestClose={() => setUploadFormModalOpen(false)}
          header="Envie o comprovante de pagamento"
          folder="evento"
          paymentId={selectedRowId}
        />
      )}
    </div>
  );
};
