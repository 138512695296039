export const StyledButton = ({ children, styles = "", ...props }) => {
  return (
    <button
      {...props}
      className={`bg-blue-700 text-white rounded p-4 m-4 focus:bg-blue-500 ${styles}`}
    >
      {children}
    </button>
  );
};
