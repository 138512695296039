import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import api from "../../utils/api";

import { StyledLabel } from "../../Labels/StyledLabel";

export const ProfileVisualize = ({ newProfile = false, data }) => {
  const { userId } = useAuth();

  const [profile, setProfile] = useState({});
  const [mode, setMode] = useState("cadastro");
  const [busLines, setBusLines] = useState([]);

  const [institutes, setInstitutes] = useState([]);

  const [list, setList] = useState({});

  useEffect(() => {
    if (userId === 0) return;
    if (data) {
      setProfile(data);
    }
    async function fetchData() {
      if (newProfile) {
        setProfile((prevState) => ({
          ...prevState,
          newProfile: newProfile,
        }));
      } else {
        try {
          const url = `/admin/profile/${userId}`;
          console.log("userId", userId, url);
          const response = await api.get(url);
          console.log("res", response);

          setProfile(response.data?.profiles[0]);
          setMode("update");
        } catch (error) {
          console.log("error", error);
        }
      }
    }

    fetchData();
  }, [userId, newProfile, data]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/bus/line");
        setBusLines(response.data.busLines);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/admin/institute");
        setInstitutes(response.data.institutes);
      } catch (error) {
        console.error("Erro ao buscar linhas de ônibus:", error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    setList({
      Nome: data["name"],
      Telefone: data["phone"],
      "e-mail": data["email"],
      RG: data["rg"],
      CPF: data["cpf"],
      "Título de eleitor": data["tituloEleitor"],
      Curso: data["curso"],
      Instituição: data["instituteId"],
      Turno: data["turno"],
      Modalidade: data["modalidade"],
      "Estado da matricula": data["estadoMatricula"],
      Periodo: data["periodo"],
    });
  }, [data]);
  console.log("profile2 ->", userId, profile);
  if (userId === 0) {
    return <div>Carregando...</div>; // ou qualquer indicador de carregamento desejado
  }
  return (
    <div className="flex items-center justify-center bg-transparent p-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md h-full w-full">
        <h1 className="flex justify-center text-2xl">Conferência de usuário</h1>

        <div className="mt-4 space-y-2">
          <div>
            {/* {Object.entries(list).map(([key, value]) => (
              <StyledLabel key={key} value={value}>
                {key}
              </StyledLabel>
            ))} */}
            <StyledLabel value={data?.name}>Nome </StyledLabel>
            <StyledLabel value={data?.phone}>Telefone </StyledLabel>
            <StyledLabel value={data?.email}>E-mail </StyledLabel>
            <StyledLabel value={data?.cpf}>CPF </StyledLabel>
            <StyledLabel value={data?.rg}>RG </StyledLabel>
            <StyledLabel value={data?.tituloEleitor}>
              Título de eleitor{" "}
            </StyledLabel>
            <StyledLabel value={data?.busLine?.name}>Linha </StyledLabel>
            <StyledLabel value={data?.institute?.name}>Faculdade </StyledLabel>
            <StyledLabel value={data?.CourseOnInstitute?.course?.name}>
              Curso{" "}
            </StyledLabel>
            <StyledLabel value={data?.CourseOnInstitute?.course?.period?.name}>
              Turno{" "}
            </StyledLabel>
          </div>
        </div>
      </div>
    </div>
  );
};
