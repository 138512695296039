import React, { useState } from "react";

import { CadastroForm } from "./CadastroForm";

import { ImageUploadForm } from "./ImageUploadForm";

export const Cadastro = () => {
  const [operacao, setOperacao] = useState("");
  // const [operacao, setOperacao] = useState("rgImage");
  // const [operacao, setOperacao] = useState("cpfImage");
  const ButtonAcao = ({ label, ...props }) => {
    return (
      <button {...props} className="bg-green-700 text-white p-4 rounded">
        {label}
      </button>
    );
  };
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md h-full w-full space-y-8">
        {operacao !== "" && (
          <ButtonAcao label={"Voltar"} onClick={() => setOperacao("")} />
        )}
        {operacao === "cadastro" && <CadastroForm />}
        {/* {operacao === "rgImage" && <RGImageForm header="Envie seu RG" />} */}
        {operacao === "residencia" && (
          <ImageUploadForm
            header="Envie seu comprovante de residencia"
            folder="residencia"
          />
        )}
        {operacao === "cpfImage" && (
          <ImageUploadForm header="Envie uma imagem de seu CPF" folder="cpf" />
        )}
        {operacao === "rgImage" && (
          <ImageUploadForm header="Envie uma imagem de seu RG" folder="rg" />
        )}  
        {operacao === "titeleitor" && (
          <ImageUploadForm
            header="Envie uma imagem de seu título de eleitor"
            folder="titeleitor"
          />
        )}
        {operacao === "foto" && (
          <ImageUploadForm header="Envie sua foto 3x4" folder="foto" />
        )}
        {operacao === "matricula" && (
          <ImageUploadForm header="Envie seu comprovante de matricula" folder="compMatricula" />
        )}
        {operacao === "" && (
          <div className="flex flex-col space-y-4">
            <ButtonAcao
              label={"Cadastrar informações"}
              onClick={() => setOperacao("cadastro")}
            />
            <ButtonAcao
              label={"Enviar foto 3x4"}
              onClick={() => setOperacao("foto")}
            />
            <ButtonAcao
              label={"Enviar Comprovante de matricula"}
              onClick={() => setOperacao("matricula")}
            />
            <ButtonAcao
              label={"Enviar comprovante de residencia"}
              onClick={() => setOperacao("residencia")}
            />
            <ButtonAcao
              label={"Enviar imagem do CPF"}
              onClick={() => setOperacao("cpfImage")}
            />
            <ButtonAcao
              label={"Enviar imagem do RG"}
              onClick={() => setOperacao("rgImage")}
            />
            <ButtonAcao
              label={"Enviar imagem do Titulo de eleitor"}
              onClick={() => setOperacao("titeleitor")}
            />
          </div>
        )}
      </div>
    </div>
  );
};
