import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";

import { format } from "date-fns";
import useAuth from "../../../hooks/useAuth";
import api from "../../../utils/api";

export const AdminOuvidoria = () => {
  const { userId } = useAuth();
  const [selectedChat, setSelectedChat] = useState(null);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensChatSelecionado, setMensagensChatSelecionado] = useState([]);
  const [messageInput, setMessageInput] = useState("");
  const [tabIndex, setTabIndex] = useState(0);

  const handleChatSelection = (chat) => {
    console.log("chat ->", chat);
    setSelectedChat(chat);
    // Simular o carregamento das mensagens do chat selecionado
    setMensagensChatSelecionado(
      mensagens.filter((mensagem) => mensagem.chatId === chat.id)
    );
  };

  useEffect(() => {
    api
      .get("/admin/messages")
      .then((response) => {
        console.log("response", response);
        setMensagens(response?.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, []);

  const updateChat = async (selectedChat) => {
    if (selectedChat?.id) {
      api
        .get(`/admin/messages/message/${selectedChat?.id}`)
        .then((response) => {
          console.log("response", response);
          setMensagensChatSelecionado(response?.data?.messages);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };
  useEffect(() => {
    console.log("-->", selectedChat?.id, selectedChat);
    updateChat(selectedChat);
  }, [selectedChat]);

  console.log("selectedChat", selectedChat);

  const handleSendMessage = () => {
    // Lógica para enviar mensagem ao chat selecionado (não implementado neste exemplo)
    console.log("send", messageInput);
    const payload = {
      content: messageInput,
      senderId: userId,
      chatMessageId: selectedChat?.id,
    };
    api
      .post("/admin/messages/message", payload)
      .then((response) => {
        console.log("response", response);
        updateChat(selectedChat);
        setMessageInput("");
      })
      .catch((error) => console.error(error));
  };

  const handleArchive = (chatId) => {
    api
      .put(`/admin/messages/archive/${chatId}`)
      .then((response) => {
        setMensagens(mensagens.filter((mensagem) => mensagem.id !== chatId));
        api
          .get("/admin/messages")
          .then((response) => {
            console.log("response", response);
            setMensagens(response?.data);
          })
          .catch((error) => {
            console.error("error", error);
          });
        if (selectedChat?.id === chatId) {
          setSelectedChat(null);
          setMensagensChatSelecionado([]);
        }
      })
      .catch((error) => console.error(error));
  };
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const filteredMensagens = mensagens.filter(
    (mensagem) => mensagem.isArchived === (tabIndex === 1)
  );

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {/* Lista de mensagens */}
          <Paper
            elevation={3}
            style={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
          >
            <Tabs value={tabIndex} onChange={handleTabChange}>
              <Tab label="Chats Ativos" />
              <Tab label="Chats Arquivados" />
            </Tabs>
            <List>
              {mensagens?.length &&
                mensagens?.map((mensagem) => {
                  const isAtiveTab = tabIndex !== 0;

                  if (mensagem.isArchived === isAtiveTab) {
                    return (
                      <div key={mensagem?.id}>
                        <ListItem
                          button
                          onClick={() =>
                            handleChatSelection({
                              id: mensagem?.id,
                              title: `Chat 1 ${mensagem?.id}`,
                            })
                          }
                        >
                          <ListItemText primary={`Chat  ${mensagem?.id}`} />
                          <IconButton
                            edge="end"
                            aria-label="archive"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleArchive(mensagem?.id);
                            }}
                          >
                            <ArchiveIcon />
                          </IconButton>
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  }
                })}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={8}>
          {/* Chat selecionado */}
          <Paper
            elevation={3}
            style={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
          >
            {selectedChat ? (
              <Box p={2}>
                <Typography variant="h6">{selectedChat.title}</Typography>
                {/* Lista de mensagens do chat selecionado */}
                <List>
                  {mensagensChatSelecionado?.length &&
                    mensagensChatSelecionado?.map((mensagem) => {
                      return (
                        <ListItem
                          key={mensagem.id}
                          style={{
                            backgroundColor:
                              mensagem.senderId === userId
                                ? "#e1f5fe" // Cor para mensagens do usuário
                                : "#e0e0e0", // Cor para mensagens de outros remetentes
                            padding: "8px",
                            width: "90%",
                            marginLeft:
                              mensagem.senderId === userId ? "auto" : "unset",
                            marginRight:
                              mensagem.senderId === userId ? "unset" : "auto",

                            borderRadius: "4px",
                            marginBottom: "8px",
                          }}
                        >
                          <ListItemText
                            primary={mensagem.content}
                            // secondary={mensagem.createdAt}
                            secondary={format(
                              new Date(mensagem.createdAt),
                              "dd/MM/yyyy HH:mm"
                            )}
                          />
                        </ListItem>
                      );
                    })}
                </List>
                <TextField
                  variant="outlined"
                  label="Digite sua mensagem"
                  fullWidth
                  margin="normal"
                  value={messageInput}
                  onChange={(e) => setMessageInput(e.target.value)}

                  // Adicionar onChange para atualizar o estado da mensagem a ser enviada
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                >
                  Enviar
                </Button>
              </Box>
            ) : (
              <Typography variant="h6">Selecione um chat</Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
