import { useGoogleLogin } from "@react-oauth/google";
import useAuth from "../../hooks/useAuth";
import { Button } from "@mui/material";

export const LoginButton = ({ label = "Login" }) => {
  const { authenticated, logout, login } = useAuth();

  const googleAuth = useGoogleLogin({
    onSuccess: async (res) => {
      await login({ access_token: res.access_token });
    },
  });
  const handleLogin = async () => {
    if (authenticated) {
      logout();
    } else {
      if (process.env.REACT_APP_ENV === "DEV") {
        await login({ access_token: "" });
      } else {
        googleAuth();
      }
    }
  };

  return (
    <Button onClick={handleLogin} color="inherit">
      {authenticated ? "LOGOUT" : label}
    </Button>
  );
};
