import { useContext } from "react";
import { ModalContext } from "../contexts/ModalsProvider";

// import { ModalContext } from "./ModalProvider";

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error("useModal deve ser usado dentro do ModalProvider");
  }

  return context;
};
