import React from "react";

export const InstituteSelect = ({ institutes, selectedId, ...rest }) => {
  return (
    <div>
      {/* {JSON.stringify(institutes)} */}
      <select
        {...rest}
        value={selectedId || ""}
        className="rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
        required
      >
        <option value="" disabled>
          Selecione uma instituição
        </option>
        {institutes?.length &&
          institutes?.map((busStop) => (
            <option key={busStop.id} value={busStop.id}>
              {busStop.name}
            </option>
          ))}
      </select>
    </div>
  );
};
