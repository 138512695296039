import React, { useEffect, useId, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import { ChatHeader } from "../../components/ChatHeader";
import { SectorSelect } from "../../components/Inputs/SectorSelect";
import { useNavigate } from "react-router-dom";

export const Ouvidoria = () => {
  const { userId } = useAuth();
  const [sector, setSector] = useState();
  const navigate = useNavigate();
  const [selectedChat, setSelectedChat] = useState(null);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensChatSelecionado, setMensagensChatSelecionado] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const handleChatSelection = (chat) => {
    console.log("chat ->", chat);
    setSelectedChat(chat);

    setMensagensChatSelecionado(
      mensagens.filter((mensagem) => mensagem.chatId === chat.id)
    );
  };

  const updateChat = async (selectedChat) => {
    if (selectedChat?.id) {
      api
        .get(`/admin/messages/message/${selectedChat?.id}`)
        .then((response) => {
          console.log("response", response);
          setMensagensChatSelecionado(response?.data?.messages);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };
  useEffect(() => {
    console.log("-->", selectedChat?.id, selectedChat);
    updateChat(selectedChat);
  }, [selectedChat]);

  console.log("selectedChat", selectedChat);

  const handleSendMessage = () => {
    // Lógica para enviar mensagem ao chat selecionado (não implementado neste exemplo)
    console.log("send", messageInput);
    const payload = {
      content: messageInput,
      senderId: userId,
      chatMessageId: selectedChat?.id,
    };
    api
      .post("/admin/messages/message", payload)
      .then((response) => {
        console.log("response", response);
        updateChat(selectedChat);
        setMessageInput("");
      })
      .catch((error) => console.error(error));
  };
  const handleSectorSelect = (sectorId) => {
    setSector({
      sectorId,
    });
  };

  const handleCreateChat = () => {
    const payload = {
      senderId: userId,
      sectorId: selectedChat?.sectorId?.id,
      content: "",
    };
    console.log("create ->", payload);
    api
      .post("/admin/messages", payload)
      .then((response) => {
        console.log("response", response);
        updateChat(selectedChat);
        setMessageInput("");
        navigate("/ouvidoria/chats");
      })
      .catch((error) => console.error(error));
  };
  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <ChatHeader />
          <SectorSelect onSelect={handleSectorSelect} />
          <Button onClick={handleCreateChat}>Criar</Button>
          {/* {JSON.stringify(sector)} */}
        </Paper>
      </Grid>
    </Grid>
  );
};
