import React from "react";
import { Modal } from "../Modal";

import { CourseForm } from "../../Forms/CourseForm";

export const CourseModal = ({ isOpen, onClose, data }) => {
  if (!isOpen) {
    return null;
  }

  let content = <CourseForm data={data} onSuccess={onClose} />;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      {content}
    </Modal>
  );
};
