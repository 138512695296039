import React, { useEffect, useId, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Box,
  Paper,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import api from "../../utils/api";
import useAuth from "../../hooks/useAuth";
import { format } from "date-fns";
import { ChatHeader } from "../../components/ChatHeader";

export const Chats = () => {
  const { userId } = useAuth();
  const [selectedChat, setSelectedChat] = useState(null);
  const [mensagens, setMensagens] = useState([]);
  const [mensagensChatSelecionado, setMensagensChatSelecionado] = useState([]);
  const [messageInput, setMessageInput] = useState("");

  const handleChatSelection = (chat) => {
    console.log("chat ->", chat);
    setSelectedChat(chat);
    // Simular o carregamento das mensagens do chat selecionado
    setMensagensChatSelecionado(
      mensagens.filter((mensagem) => mensagem.chatId === chat.id)
    );
  };

  useEffect(() => {
    api
      .get("/admin/messages")
      .then((response) => {
        console.log("response", response);
        setMensagens(response?.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }, []);

  const updateChat = async (selectedChat) => {
    if (selectedChat?.id) {
      api
        .get(`/admin/messages/message/${selectedChat?.id}`)
        .then((response) => {
          console.log("response", response);
          setMensagensChatSelecionado(response?.data?.messages);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };
  useEffect(() => {
    updateChat(selectedChat);
  }, [selectedChat]);

  console.log("selectedChat", selectedChat);

  const handleSendMessage = () => {
    // Lógica para enviar mensagem ao chat selecionado (não implementado neste exemplo)
    console.log("send", messageInput);
    const payload = {
      content: messageInput,
      senderId: userId,
      chatMessageId: selectedChat?.id,
    };
    api
      .post("/admin/messages/message", payload)
      .then((response) => {
        console.log("response", response);
        updateChat(selectedChat);
        setMessageInput("");
      })
      .catch((error) => console.error(error));
  };

  return (
    <div>
      <ChatHeader />
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {/* Lista de mensagens */}
            <Paper
              elevation={3}
              style={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
            >
              <List>
                {mensagens?.length &&
                  mensagens?.map((mensagem) => {
                    return (
                      <div key={mensagem?.id}>
                        <ListItem
                          button
                          onClick={() =>
                            handleChatSelection({
                              id: mensagem?.id,
                              title: `Chat ${mensagem?.id}`,
                            })
                          }
                        >
                          <ListItemText primary={`Chat ${mensagem?.id}`} />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  })}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={8}>
            {/* Chat selecionado */}
            <Paper
              elevation={3}
              style={{ maxHeight: "calc(100vh - 120px)", overflow: "auto" }}
            >
              {selectedChat ? (
                <Box p={2}>
                  <Typography variant="h6">{selectedChat.title}</Typography>
                  {/* Lista de mensagens do chat selecionado */}
                  {mensagensChatSelecionado?.length ? (
                    <List>
                      {mensagensChatSelecionado.map((mensagem) => (
                        <ListItem
                          key={mensagem.id}
                          style={{
                            backgroundColor:
                              mensagem.senderId === userId
                                ? "#e1f5fe"
                                : "#e0e0e0",
                            padding: "8px",
                            width: "90%",
                            marginLeft:
                              mensagem.senderId === userId ? "auto" : "unset",
                            marginRight:
                              mensagem.senderId === userId ? "unset" : "auto",
                            borderRadius: "4px",
                            marginBottom: "8px",
                          }}
                        >
                          <ListItemText
                            primary={mensagem.content}
                            secondary={format(
                              new Date(mensagem.createdAt),
                              "dd/MM/yyyy HH:mm"
                            )}
                          />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <Typography variant="body1">
                      Nenhuma mensagem encontrada
                    </Typography>
                  )}
                  <TextField
                    variant="outlined"
                    label="Digite sua mensagem"
                    fullWidth
                    margin="normal"
                    value={messageInput}
                    onChange={(e) => setMessageInput(e.target.value)}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSendMessage}
                  >
                    Enviar
                  </Button>
                </Box>
              ) : (
                <Typography variant="h6">Selecione um chat</Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
